import React from 'react';
import { AppWrapper, baseInsuranceUrl } from '@capcenter/shared';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Insurance from './Insurance';
import InsuranceQuote from './InsuranceQuote';

function App() {
  return (
    <Router basename={'/insurance-quote'}>
      <Switch>
        <Route path={`/`} exact render={() => <Redirect to="/savings" />} />
        <Route
          path={`/savings`}
          render={() => (
            <AppWrapper>
              <Insurance app="requestInsuranceSavings" />
            </AppWrapper>
          )}
        />
        <Route
          path={`/buy`}
          render={() => (
            <AppWrapper>
              <InsuranceQuote app="requestInsuranceBuy" />
            </AppWrapper>
          )}
        />
        <Route
          path={`/refi`}
          render={() => (
            <AppWrapper>
              <InsuranceQuote app="requestInsuranceRefi"/>
            </AppWrapper>
          )}
        />
        <Route
          path="*"
          component={() => {
            window.location.href = `${baseInsuranceUrl}/404`;
            return null;
          }}
        />
      </Switch>
    </Router>
  );
}

export default App;
