import React, { Component } from 'react';
import './InsuranceHero.scss';

export class InsuranceHero extends Component {
  render() {
    return (
      <div className='buy-hero'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='buy-hero-left col-lg'>

             </div>
            <div className='buy-hero-right col-lg buy-hero-image'>

            </div>
          </div>
        </div>
        <div className='container text-overlap'>
          <div className='row'>
            <div className='buy-hero-left col-lg buy-hero-main'>
              <h1>Save on home, auto, and life insurance policies, too.</h1>
              <h2>We shop the best premium with your desired coverage across our large network of reliable carriers.</h2>

              <div className='row'>
                <div className='col-sm'>
                  <a href='/insurance-quote/buy' title="Get a free quote and save!"><button className='btn-primary'>Get a free quote</button></a>
                </div>
                <div className='col-sm'>
                  <a href='mailto:insurance@capcenter.com' title="Email our insurance team"><button>Email insurance team</button></a>
                </div>
              </div>
            </div>
            <div className='buy-hero-right col-lg'>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InsuranceHero;