import * as React from "react"
import { IAuthenticationContext } from "./IAuthenticationContext";

const AuthenticationContext = React.createContext<IAuthenticationContext>({
  getToken: () => { console.warn('No Authentication Provider'); return undefined },
  setCoreAccountInformation: () => console.warn ('No Authentication Provider'),
  wipeAccountFromMemory: () => console.warn('No Authentication Provider'),
  updateAuthState: () => new Promise(()=>{console.warn('No Authentication Provider')}),
  account: {}
})

export const useAuthenticationContext = () => React.useContext(AuthenticationContext)

export default AuthenticationContext


