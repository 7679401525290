import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button } from '../Buttons/DefaultButton/Button'
import { initializeApp } from '@capcenter/firebase-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faXmark } from '@fortawesome/pro-regular-svg-icons'
import { googleLogout } from '@react-oauth/google';
import { firebaseAuth } from '../../services/Firebase';

export enum authLevel {
  zero = 0,
  one = 1,
  two = 2,
  three = 3,
  four = 4
}

type TimeoutDurationResponse = {
  timeLeft: number;
  canRefresh: boolean;
  authLevel: authLevel;
}

async function getTimeoutDuration(): Promise<TimeoutDurationResponse> {
  const response = await fetch(`/accountapi/account/timeout`);
  const {timeLeft, canRefresh, authLevel} = await response.json();
  return {timeLeft, canRefresh, authLevel} as TimeoutDurationResponse;
}

export async function verifyLogin(callback?: ()=>void, retry=false) {
  try{
    if(window.location.host.includes("capcenter.com") || window.location.host.includes("localhost")){
      const response = await fetch(`/accountapi/account/validate-login`);
      const status = response.status;
      if(status<400 && callback){
        callback();
      } else if (retry) {
        sleep(60*1000).then(()=>verifyLogin(callback, retry));
      }
      return response;
    }
  }
  catch(error){
    console.error(error);
  }
}
export const sessionLogout = async (level?: authLevel) => {
  try {
    const options = {
      method: 'post',
    }

    const href = level ? (level >= authLevel.three ? '/account/' : '/') : '/'

    await fetch(`/firebaseAuth/session/logout`, options)
     await firebaseAuth.signOut();
     await googleLogout();
     window.location.href = href;
  } catch (error) {
    console.error('Logging user out', error)
  }
}

function sleep(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function LoginTimeoutModal(props: {retryLoginCheck?: boolean}) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState<number>();
  const [canRefresh, setCanRefresh] = useState<boolean>(true);
  const [modalPermanentlyHidden, setModalPermanentlyHidden] = useState<boolean>(false);
  const [userAuthLevel, setUserUserAuthLevel] = useState<authLevel>();

  async function setTime(){
    getTimeoutDuration()
      .then(({timeLeft, canRefresh, authLevel}: TimeoutDurationResponse) => {
        setTimeLeft(timeLeft);
        setCanRefresh(canRefresh);
        setUserUserAuthLevel(authLevel);
        setLoading(false);
      })
  }

  function logout() {
    sessionLogout(userAuthLevel);
  }

  function timeToString(){
    if(timeLeft && timeLeft > 0){
      const numSeconds = timeLeft % 60;
      const secondsString = numSeconds !== 1 ? `${numSeconds} seconds` : `1 second`
      const numMinutes = Math.floor(timeLeft/60);
      return (numMinutes ? (numMinutes !==1 ? `${numMinutes} minutes ${secondsString}`: `1 minute ${secondsString}`): secondsString);
    } else {
      return '0 seconds'
    }
  }

  useEffect(()=>{
    verifyLogin(setTime, props.retryLoginCheck);
  },[])

  useEffect(()=>{
    if(timeLeft!=undefined){

      if(timeLeft > 300){
        setShow(false);
        sleep(60*1000).then(setTime);
      } else if (timeLeft <= 0){
        logout();
      } else {
        setShow(true);
        sleep(1000).then(setTime);
      }
    }
  }, [timeLeft])

  return (
    <>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show && !modalPermanentlyHidden && props.retryLoginCheck}
      >
        <Modal.Body>
          <div className='d-flex justify-content-between'>
            <Modal.Title>{canRefresh? 'Session Timeout Warning': 'Max Session Timeout Warning'}</Modal.Title>
            <div className='mr-1'>
              <a  onClick={()=>canRefresh ? verifyLogin(setTime): setModalPermanentlyHidden(true)} href="#" style={{color:"#647778", textDecoration: "none"}}
                title={canRefresh?"Close this Dialog and Extend Session":"Close this Dialog and Continue Until End of Session"}>
                <FontAwesomeIcon icon={faXmark} />
              </a>
            </div>
          </div>
          <div className='mb-4'>
            { loading? "Loading..." :
              userAuthLevel && userAuthLevel <= authLevel.two
                ? canRefresh
                  ? `Session will time out in ${timeToString()} if no user activity is detected. Check your email for a link to continue your session. You will be redirected to the home page when the session times out.`
                  : `Session will time out in ${timeToString()} because the maximum session time limit has been reached.  Check your email for a link to continue your session. You will be redirected to the home page when the session times out.`
                : canRefresh
                  ? `Session will time out in ${timeToString()} if no user activity is detected. You will be redirected to the login page when the session times out.`
                  : `Session will time out in ${timeToString()} because the maximum session time limit has been reached. You will be redirected to the login page when the session times out.`}
          </div>
          <div className='d-flex justify-content-between '>
          <Button resetText color="dark-red" onClick={logout} title="End Session"
            label={userAuthLevel && userAuthLevel <= authLevel.two ? "End Session": "Logout"}/>
           <Button resetText color="dark-green" onClick={()=>canRefresh ? verifyLogin(setTime): setModalPermanentlyHidden(true)} label={canRefresh? 'Extend Session': 'Continue Working Until Session Timeout'}
            title={canRefresh? "Close this Dialog and Extend Session": "Cannot Extend Session Because Maximum Session Time Reached.  Click Here to Close This Dialog."}/>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
