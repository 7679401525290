import React, {Component} from 'react'
import { getUrlWithUtmQueryString } from '@capcenter/shared';

export class WhyCapCenter extends Component {

  constructor(props) {
    super(props);  
    this.state = {
    }
  }

  render() {
    return (
	    
			<div className="container number-one-rtb">
				<div className="d-flex flex-column">
					<div className="d-flex flex-row align-content-start align-items-stretch flex-wrap justify-content-center">
						<div className="d-flex justify-content-center" style={{flex:0, flexGrow: 1, flexShrink: 1, minWidth: '300px'}}>
							<div className="d-block number-one-left-col">
								<div className="number-one-title">#1 Mortgage Lender</div>
								<div className="number-one-caption">Richmond, Virginia</div>
								<p className='large'>
									CapCenter is the <strong>number one mortgage lender in Richmond, VA in 2023</strong> by <a href="https://richmondmagazine.com/" target="_blank" rel="noreferrer noopener">Richmond Magazine</a>. Thank you to the readers of Richmond Magazine for voting us number one!
								</p>
								<p className='large'>
									CapCenter is the <strong>number one mortgage lender in Richmond, VA</strong> metropolitan statistical area (40060) based on application volume data in the 2021 Consumer Financial Protection Bureau (CFPB) Home Mortgage Disclosure Act (HMDA) data (<a href="https://www.consumerfinance.gov/" target="_blank" rel="noreferrer noopener">consumerfinance.gov</a>).
								</p>
								<p>
									The <a href="https://www.consumerfinance.gov/data-research/hmda/" target="_blank" rel="noreferrer noopener">CFPB HMDA data</a> includes data for lenders such as
								</p>
								<ul>
									<li>Wells Fargo Bank</li>
									<li>Bank of America</li>
									<li>Truist</li>
									<li>Rocket Mortgage</li>
									<li>Virginia Credit Union</li>
									<li>Navy Federal Credit Union</li>
									<li>Loan Depot</li>
									<li>... and many more</li>
								</ul>
								<p className='large'>
									We're still the same family-owned, local business that started in 1997 on a mission to provide the best home ownership services to our community.
									We employ local residents, and our employees love to use our services too!
								</p>
							</div>
						</div>
						<div className="d-block" style={{flex:0, flexGrow: 1, flexShrink: 1, minWidth: '300px'}}>
							<div className="d-block number-one-right-col">
								<div className="number-one-title-2">Great rates, Zero Closing Costs</div>
								<p className='large'>
									By combining realty, mortgage, and insurance services under one roof, CapCenter is able to provide unparalleled savings & service.
								</p>
								<p className='large'>
									Our business is built on honest rates, honest fees, and trustworthy service. Our <a href={getUrlWithUtmQueryString("/mortgage-calculator/purchase")}>online mortgage calculator</a> provides free, anonymous quotes with estimated rates, fees, cash to close, and monthly payments.
								</p>
								<p className='large'>
									Did you know that our past clients are our biggest supporters? If you don't know anyone that used CapCenter before, give us a <a href='tel:8049685000'>call</a>, and we'll help you get started.
								</p>
							</div>
							<br />
							<div className="d-block number-one-right-col">
								<div className="number-one-title-2">Thank you, Richmond, Virginia!</div>
								<p className='large'>For helping us build our story since the very beginning in 1997. We appreciate your business and continued support, and we'll be here to help build and support your home ownership needs and goals.</p>
								<p className='large'>
									Stay up to date with our rate alert, realty market, and corporate update <a href={getUrlWithUtmQueryString("/forms/rate-alerts")}>email subscriptions</a>. See you at our next <a href="https://blog.capcenter.com/faq/what-is-capcenter-pie-day" target="_blank" rel="noreferrer noopener">pie day</a>!
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
	    

    );
  }
}

export default WhyCapCenter

