import React from 'react';
import { Break } from '@capcenter/shared-legacy';

import '@capcenter/shared-legacy/src/constants/GlobalStyles.scss';
import {AppHelmet} from "@capcenter/shared";

function InsuranceQuote(props?: any) {

  return (
    <>
      <AppHelmet app={props.app} />
      <iframe
        title="Insurance Quote"
        id="TheFrame"
        name="TheFrame"
        src="https://secure.consumerratequotes.com/ConsumerV2?id=58267"
        style={{
          width: '100%',
          minHeight: '1000px',
          height: '100%',
          border: 0,
          borderTop: '20px solid white',
        }}
      ></iframe>
      <h1 className={'text-center'}>Email our <a href="mailto:insurance@capcenter.com">insurance team</a> for a custom quote</h1>
      <Break />
    </>
  );
}

export default InsuranceQuote;
