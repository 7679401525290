import React from 'react';
import {
  RTB,
  RTBImages,
  RTBImage,
  ImageCapCenterHQ,
  ImageCapCenterTeam,
  WhyCapCenter
} from '@capcenter/shared-legacy';
import InsuranceHero from './hero/InsuranceHero';
import HomeImage from './images/home.png';
import AutoImage from './images/auto.png';
import UmbrellaImage from './images/umbrella.png';
import ParkedImage from './images/parked.png';
import {AppHelmet} from "@capcenter/shared";

function Insurance(props?: any) {

  return (
    <>
      <AppHelmet app={props.app} />

      <InsuranceHero></InsuranceHero>

      <RTB title="Our insurance policies" text={['We offer homeowner\'s, property, renter\'s, auto, boat, RV, ATV, motorcycle, umbrella, small business, valuable items (such as jewelry) and life insurance policies.<br /><br /><a href="/insurance-quote/buy" target="_blank">Get a free quote</a> for auto, homeowners, or renters insurance or <a href="mailto:insurance@capcenter.com" target="_blank">email our insurance team</a> for other policies or questions.']}>
        <RTBImages images={[HomeImage, AutoImage, UmbrellaImage, ParkedImage]} />
      </RTB>

      <RTB title="Why get a quote?" text={['It\'s quick, it\'s easy, and you may never need to shop around for homeowner\'s insurance again!<br /><br />If you\'re using CapCenter for purchase or refinance, we will automatically provide our best quote without you having to call anyone with detailed specifications of your house saving you time and money.<br /><br />We save our clients over 20% from their existing homeowner\'s insurance, on average. You\'ll never stop saving because we work with over 35 carriers to monitor the best price for your coverage <strong>EVERY year, automatically</strong>.<br /><br />For the same coverage, our insurance team should be able to beat individually quoted rates with CapCenter\'s group purchasing power. Did you know that our insurance department has saved our clients over $1 million since we launched in 2020?<br /><br /><a href="/insurance-quote/buy" target="_blank">Get a free quote</a> or <a href="mailto:insurance@capcenter.com" target="_blank">email our insurance team</a> for more information.']}>
        <WhyCapCenter />
      </RTB>

      <RTB title="Hassle-free savings" text={['Our insurance team automatically provides you our best quote from multiple carriers without you having to provide detailed specifications of your house to us or other agencies to get a few quotes. Do you know how close the nearest fire hydrant is to your new home? Many insurance agencies will ask you this among many other questions, but we can pull up all of the information for most properties. We love saving you time and money, and it\'s just that much easier when you use CapCenter for purchasing or refinancing your home.<br/><br/>Did you know the closing process is easier if homeowners insurance is issued at least seven days before closing?<br/><br/>If the initial quote isn\'t exactly what you pictured, we can adjust insurance coverages and deductibles to find the perfect policy that best meets your needs. Save even more by bundling auto insurance, which can be bundled after closing to reduce your stress.']}>
        <RTBImage image={ImageCapCenterTeam} />
      </RTB>

      {/* <RTB
        title="Five-star customer reviews"
        text={['95% of our clients would recommend us to a family member or a friend. <br /><br />Read or watch CapCenter\'s client reviews on <a href="https://www.trustpilot.com/review/www.capcenter.com" target="_blank">Trustpilot</a>, <a href="https://goo.gl/maps/yD6wUNqCnUP75RYj8" target="_blank">Google</a>, <a href="https://www.zillow.com/profile/CapCenterLLC" target="_blank">Zillow</a>, or <a href="https://www.youtube.com/channel/UCoFRaNzAh-9NkVRxe2eTNwA/videos" target="_blank">YouTube</a>.']}
      >
        <TrustPilot />
      </RTB> */}

      <RTB title="Do you have any questions?" text={['<div class="text-left">Contact us via web chat, phone <span style="white-space: nowrap;"><a href="tel:18009685844">1-800-968-5844</a></span>, or email <a href="mailto:insurance@capcenter.com">insurance@capcenter.com</a>, or <a href="https://faq.capcenter.com/" target="_blank">search our knowledge base</a> of commonly asked questions.</div>']}>
        <RTBImage image={ImageCapCenterHQ} />
      </RTB>
    </>
  );
}

export default Insurance;
