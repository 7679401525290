import React from 'react';
import QuoteBtns from './QuoteBtns.json';
import MakeOptionExceptions from '../MakeOptionExceptions';
import { Colors } from '@capcenter/shared';
import { BtnGroup } from '@capcenter/shared-legacy';

type Option = {
  Name: string;
  HighestLTV: number;
  RegionID: number;
};

const initialState = {
  theseBtns: [
    {
      disabled: true,
      text: '',
    },
  ],
};

type StateType = {
  theseBtns: Array<any>;
};

type State = Readonly<typeof initialState>;

export default class QuoteBtnGroup extends React.Component<{
  onClick: Function;
  active: string;
  fields: any;
  hideActive?: Boolean;
  inputKey: string;
  disabled: Boolean;
  invalid?: Boolean;
  refi?: Boolean;
  rates?: Boolean;
  all?: Boolean;
  availability: any;
  exceptions: any;
}> {
  readonly state: StateType = initialState;

  componentDidMount() {
    this.buildBtns();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.buildBtns();
    }
  }

  buildBtns = () => {
    let theseOptions = [] as any;

    const thisProps = this.props;

    const fields = thisProps.fields;

    // CREATE BUTTON OPTIONS

    if (Object.keys(this.props.availability).length !== 0) {
      if (this.props.all) {
        const allValue = 'All';
        const thisIsActive = thisProps.hideActive ? false : this.props.fields[thisProps.inputKey] === allValue;

        const thisOption = {
          id: 'quote--' + thisProps.inputKey + allValue + '-button',
          className: 'btn-' + thisProps.inputKey,
          text: allValue,
          active: thisIsActive,
          onClick: () => thisProps.onClick(allValue, thisProps),
          tooltip: '',
          tooltipTitle: '',
          disabled: this.props.disabled,
          disabledTooltip: '',
          icon: '',
        };

        theseOptions.push(thisOption);
      }

      (Object.keys(this.props.availability.Options) as Array<string>).forEach((optionType: string, i: number) => {
        // IF THE BUTTON TYPE MATCHES, START PROCESSING

        const idType: string = optionType;

        if (thisProps.inputKey && idType === thisProps.inputKey) {
          // LOOP THROUGH EACH OPTION (BUTTON) FOR THE CURRENT TYPE (BUTTON GROUP)

          this.props.availability.Options[idType].map((optionObj: any) => {
            const option = optionObj.Name;

            if (this.props.refi ? option !== 'Purchase' && option !== 'Home Equity Loan' : (this.props.rates || (option !== 'Cash Out Refinance'))) {
              let { disabled, disabledTooltip } = MakeOptionExceptions(
                this.props.availability.ProductCombinations,
                this.props.exceptions,
                fields,
                idType,
                option,
              );

              let thisText: string = option;
              let thisValue: string = '';
              let thisTooltip: string = '';
              let thisTooltipTitle: string = '';
              let thisIcon: any = '';
              let thisIdentifier: string = '';

              if (this.props.refi && option === 'Refinance') {
                thisText = 'Standard';
              }

              QuoteBtns.map(btn => {
                if (btn.key === option) {
                  thisText = btn.altText ? btn.altText : thisText;
                  thisValue = btn.key;
                  thisTooltip = btn.tooltip;
                  thisTooltipTitle = btn.tooltipTitle ? btn.tooltipTitle : '';
                  thisIcon = btn.icon ? btn.icon : '';
                  thisIdentifier = btn.key.toLowerCase().replace(/\s/g, "-");
                }
                return null;
              });

              const thisIsActive = thisProps.hideActive ? false : this.props.fields[thisProps.inputKey] === thisValue;

              const thisOption = {
                id: 'quote--' + thisIdentifier + '-button',
                className: 'btn-' + thisIdentifier,
                text: thisText + (thisProps.inputKey === 'LoanTerm' && thisIsActive ? ' Years' : ''),
                active: thisIsActive,
                onClick: () => thisProps.onClick(thisValue, thisProps),
                tooltip: thisTooltip,
                tooltipTitle: thisTooltipTitle,
                disabled: disabled || this.props.disabled,
                disabledTooltip: disabledTooltip,
                icon: thisIcon,
              };

              theseOptions.push(thisOption);
            }

            return null;
          });

          if (theseOptions.length === 1) {
            if (theseOptions[0].active === false) {
              theseOptions[0].onClick();
            }
          }
        }
      });
      this.setState({ theseBtns: theseOptions });
    }
  };

  render() {
    return (
      <BtnGroup
        color={Colors.orangeSecondary}
        invalid={this.props.invalid}
        btns={this.state.theseBtns}
        id={this.props.inputKey}
      />
    );
  }
}
